import React, { useState, useEffect } from 'react';
import { Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography, Box, LinearProgress, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Toolbar, Radio, Checkbox, RadioGroup, Backdrop} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider'

import TipBox from "./../utils/TipBox"

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LabelList, Funnel, FunnelChart, ResponsiveContainer } from 'recharts';
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import axios from "axios"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const funnelColors = ["#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c"]

const allCpAttributes = [
  {value: "industries", name: "Industry"},
  {value: "verticals", name: "Vertical"},
  {value: "company_size", name: "Company Size"},
  {value: "team_size", name: "Team Size"},
  {value: "geographies", name: "Geography"},
  {value: "buying_signals_tech", name: "Technical Buying Signals"},
  {value: "buying_signals_non_tech", name: "Non-Technical Buying Signals"},
]

var allCpAttributesMap = {}
for(var i=0;i<allCpAttributes.length;i+=1){
  allCpAttributesMap[allCpAttributes[i]["value"]] = allCpAttributes[i]["name"]
}

var activeCpAttributes = {}
for(var i=0;i<allCpAttributes.length;i+=1){
  activeCpAttributes[allCpAttributes[i]["value"]] = false
}

const getColor = (val, den) => {
  if( val/den >= 0.70) return "#7fe07b"
  if (val/den >= 0.40) return "#f8fa98"
  return "#f78181"
}

const LinearProgressWithLabel = (props) => {
  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
    <Typography fontSize="13">Progress</Typography>
    </>
  )
}

export default function CampaignAnalysis(props){
  const [activeCampaign, setActiveCampaign] = useState(null);
  const [cpAttributes, setCpAttributes] = useState({})
  const [attributeCombos, setAttributeCombos] = useState([])
  const [resultData, setResultData] = useState([])
  let history = useHistory()

  useEffect(() => {
    for(var i=0;i<props.content.length;i+=1){
      if(props.content[i]["_id"]["$oid"] === props.match.params.c_id){
        var activeCampaign = props.content[i]

        props.callback("heading", "Campaign Dashboard")
        props.callback("description", activeCampaign["name"])

        axios.post(BACKEND_ADDRESS + '/campaign/funnel_performance/', {campaign_id: activeCampaign["_id"]["$oid"]})
        .then(response => {
          activeCampaign["funnelPerformance"] = response.data["funnel_performance"]
          activeCampaign["outreachStats"] = response.data["outreach_stats"]
          for(var i=0;i<activeCampaign["funnelPerformance"].length;i+=1){
            activeCampaign["funnelPerformance"][i]["fill"] = funnelColors[i]
          }

          axios.post(BACKEND_ADDRESS + '/campaign/buying_intent/', {campaign_id: activeCampaign["_id"]["$oid"]})
          .then(response => {
            activeCampaign["buyingIntent"] = response.data["buying_intent"]
            for(var i=0;i<activeCampaign["cp_attributes"].length;i+=1){
              activeCpAttributes[activeCampaign["cp_attributes"][i]] = true
            }

            setCpAttributes(activeCpAttributes)
            setActiveCampaign(activeCampaign)
          })
        })

        // axios.post(BACKEND_ADDRESS + '/campaign/buying_intent/', {campaign_id: activeCampaign["_id"]["$oid"]})
        // .then(response => {
        //   activeCampaign["buyingIntent"] = response.data["buying_intent"]
        //   setActiveCampaign(activeCampaign)
        // })

        // for(var i=0;i<activeCampaign["cp_attributes"].length;i+=1){
        //   activeCpAttributes[activeCampaign["cp_attributes"][i]] = true
        // }
        // setCpAttributes(activeCpAttributes)

        break
      }
    }
  }, [])

  useEffect(() => {
    if(cpAttributes && activeCampaign){
      //updating row values
      var attributes = []
      var combos = []
      const keys = Object.keys(cpAttributes)

      for(var i=0;i<keys.length;i+=1){
        if(cpAttributes[keys[i]]) attributes.push(keys[i])
        if(attributes.length == 2) break
      }

      if(attributes.length === 1){
        combos = activeCampaign["cp"][attributes[0]].map(x => [x])
      }
      if(attributes.length === 2){
        var ats1 = activeCampaign["cp"][attributes[0]]
        var ats2 = activeCampaign["cp"][attributes[1]]

        if(ats1.length === 0) combos = activeCampaign["cp"][attributes[1]].map(x => [x])
        else if(ats2.length === 0) combos = activeCampaign["cp"][attributes[0]].map(x => [x])
        else{
          for(i=0;i<ats1.length;i+=1){
            for (var j=0;j<ats2.length;j+=1){
              combos.push([ats1[i], ats2[j]])
            }
          }
        }
      }

      setAttributeCombos(combos)

      if(activeCampaign){
        const requestData = {
          campaign_id: activeCampaign["_id"]["$oid"],
          cp_attributes: attributes,
          combos: combos,
        }

        axios.post(BACKEND_ADDRESS + '/campaign/problem_validation/', requestData)
        .then(response => setResultData(response.data["problem_validation"]))
      }
    }
  }, [cpAttributes, activeCampaign])

  const handleAttributeChange = (event) => {
    var temp = JSON.parse(JSON.stringify(cpAttributes))
    temp[event.target.name] = event.target.checked
    setCpAttributes(temp)
  };

  const overallPV = (res) => {
    if(res[0] <= 0) return 0
    var maxVal = 0
    for(var i=1;i<res.length;i+=1){
      if(i%3 === 0) maxVal = Math.max(maxVal, res[i]/res[0])
    }
    return maxVal
  }

  var progress = 0

  if(activeCampaign){
    var totalLeads = activeCampaign["filtered_leads"].reduce((tot, val) => tot + val.reduce((t, v) => t + v, 0), 0)
    var endingTypes = ["No Reply", "Replied, but Not Interested", "Conversation Done"]
    progress = 100*(activeCampaign["lead_status"].reduce((tot, val) => tot + val.reduce((t, v) => t + (v.length > 1 && v[v.length-1]["status"] === "completed" && endingTypes.includes(v[v.length-1]["type"]) ), 0), 0))/totalLeads
  }

  return (
    <>
    {activeCampaign !== null?
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
        <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
          <Stack direction="column" spacing={2} justifyContent="flex-start" >
            <Stack direction="column">
              <Typography variant="h6">{activeCampaign["name"]}</Typography>
              <LinearProgressWithLabel value={progress} />
            </Stack>
            <Stack direction="column">
              <Typography color="primary">Customer Profile</Typography>
              <Typography color="black">{activeCampaign["cp"]["name"]}</Typography>
            </Stack>
            <Stack direction="column">
              <Typography color="primary">Attributes Under Validation</Typography>
              {
              activeCampaign["cp_attributes"].map((x, ind) =>
              <Stack key={ind} direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                <Typography color="black">{allCpAttributesMap[x]}:</Typography> {activeCampaign["cp"][x].map((y, ind2) => <Chip key={ind2} label={y} size="small" />)}
              </Stack>)
              }
            </Stack>
            <Stack direction="column" alignItems="flex-start">
              <Typography color="primary">Product Ready?</Typography>
              <Chip size="small" label={activeCampaign["has_product"]?"Yes":"No"}/>
            </Stack>
          </Stack>
        </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
        <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
          {/*<Backdrop open={true} />
          */}
          <Typography variant="h6">Campaign Performance</Typography>
          <br />
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around" sx={{flexWrap: "wrap"}}>
            <FunnelChart width={350} height={180}>
              <Funnel dataKey="value" data={activeCampaign["funnelPerformance"]} isAnimationActive>
                <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
                <LabelList position="center" fill="#000" stroke="none" dataKey="value" />
              </Funnel>
            </FunnelChart>

            <Stack direction="column" spacing={1} alignItems="center">
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead style={{backgroundColor: "#f5f5f5"}}>
                  <TableRow>
                    <TableCell sx={{width: "170"}}>Channel</TableCell>
                    <TableCell sx={{width: "120"}}>No. of Reachouts</TableCell>
                    <TableCell sx={{width: "140"}}>(Reply / Reachout) %</TableCell>
                    <TableCell sx={{width: "140"}}>(Conversation / Reachout) %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeCampaign["outreachStats"].map((x, ind) => {
                    return (
                      <TableRow key={ind}>
                        <TableCell sx={{backgroundColor: "#f5f5f5"}}><Typography>{x["channel"]}</Typography></TableCell>
                        <TableCell>{x["total"]}</TableCell>
                        <TableCell>{x["total"]>0?Math.round(10000*x["reply_to_contact"]/x["total"])/100:0}%</TableCell>
                        <TableCell>{x["total"]>0?Math.round(10000*x["conversation_to_contact"]/x["total"])/100:0}%</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography>Channel-Wise Campaign Performance</Typography>
            </Stack>
          </Stack>
        </Paper>
        </Grid>

        <Grid item xs={12}>
        <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">Problem Validation</Typography>
          <Typography>The table below summarises the extent of problem validation for every buyer problem, across campaign. Below are the questions that were asked, for reference-</Typography>
          <Typography>Q1: Buyer confirmed the discussed problem as critical?<br />Q2: Buyer articulated (with concrete stories & examples) what they are unable to achieve due to problem?<br />Q3: Buyer had actively invested money/effort/time/tech/human resources to solve the problem?</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead style={{backgroundColor: "#f5f5f5"}}>
                <TableRow>
                  <TableCell colSpan={3+3*activeCampaign["cp"]["problems"].length} >
                    <FormControl size="small" key={cpAttributes}>
                      <FormLabel>Select Attributes</FormLabel>
                      <FormGroup row>
                        {allCpAttributes.map((x, ind) => {
                          if(cpAttributes[x["value"]] !== undefined)
                            return (
                              <FormControlLabel label={x["name"]} key={ind}
                              control={<Checkbox size="small" checked={cpAttributes[x["value"]]} onChange={handleAttributeChange} name={x["value"]} key={ind}/>} />
                            )
                          return (<React.Fragment key={ind}></React.Fragment>)
                        })}
                      </FormGroup>
                      <FormHelperText sx={Object.values(cpAttributes).reduce((total, v) => total+v, 0) > 2?{color:"red"}:{}}>{Object.values(cpAttributes).reduce((total, v) => total+v, 0) > 2?"Can't Select >2 attributes at a time":"All Good!"}</FormHelperText>
                    </FormControl>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={3} align="center">
                    <Typography>Attribute Values</Typography>
                  </TableCell>
                  <TableCell rowSpan={3} align="center">
                    <Typography># of Conversations (N)</Typography>
                  </TableCell>
                  <TableCell align="center" colSpan={3*activeCampaign["cp"]["problems"].length}><Typography>Buyer Problems</Typography></TableCell>
                  <TableCell rowSpan={3} align="center">
                    <Tooltip title="Overall Problem Validation is High for Attribute Value if Q3/N is higher than 70%, for at least one Buyer Problem">
                      <Typography alignItems="center">Overall Problem Validation
                      <HelpOutlineIcon fontSize="small" sx={{verticalAlign: 'middle', fontSize: '18'}}/>
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {activeCampaign["cp"]["problems"].map((x, ind)=> {
                    return (
                      <TableCell key={ind} align="center" colSpan={3} sx={ind+1!==activeCampaign["cp"]["problems"].length?{borderRight: "2px solid #cccccc"}:{}}>
                        {x}
                      </TableCell>
                    )
                  })}
                </TableRow>
                <TableRow>
                  {activeCampaign["cp"]["problems"].map((_, ind1) => {
                    return (
                      <React.Fragment key={ind1}>
                      {["Q1", "Q2", "Q3"].map((x, ind2) => {
                        return <TableCell key={ind2} sx={ind2 == 2 && ind1+1 !== activeCampaign["cp"]["problems"].length?{borderRight: "2px solid #cccccc"}: {}}>{x}</TableCell>
                      })
                      }
                      </React.Fragment>
                    )
                  })}
                </TableRow>

              </TableHead>
              <TableBody>
                {resultData.length === attributeCombos.length?
                attributeCombos.map((combo, ind1) => {
                  return (
                    <TableRow key={ind1}>
                      <TableCell style={{backgroundColor: "#f5f5f5"}}>
                        <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
                          {combo.map((val, ind2) => {
                            return (
                              <Chip key={ind2} size="small" label={val} style={{color: "#395af6"}}/>
                            )
                          })}
                        </Stack>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{resultData[ind1][0]}</Typography>
                      </TableCell>
                      {resultData[ind1].slice(1).map((cellResult, ind2) => {
                        return (
                          <TableCell key={ind2} sx={(ind2+1)%3 === 0 && ind2+1 !== resultData[ind1].length?{backgroundColor: getColor(cellResult, resultData[ind1][0]), borderRight: "2px solid #cccccc"}: {backgroundColor: getColor(cellResult, resultData[ind1][0])}}>{cellResult}</TableCell>
                        )
                      })}
                      <TableCell align="center">
                        {overallPV(resultData[ind1]) >= 0.7?
                          <><Typography variant="h6">{Math.round(10000*overallPV(resultData[ind1]))/100 + "%"}</Typography><Typography color="#00cc33">High</Typography></>
                          :
                          <><Typography variant="h6">{Math.round(10000*overallPV(resultData[ind1]))/100 + "%"}</Typography><Typography color="red">Low</Typography></>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })
                :console.log("Loading!")
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        </Paper>
        </Grid>

        <Grid item xs={12}>
        <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">Buying Intent</Typography>
          <Typography>The table below summarises buying intent for every persona, across campaign.</Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead style={{backgroundColor: "#f5f5f5"}}>
                <TableRow>
                  <TableCell rowSpan={2}><Typography>Persona</Typography></TableCell>
                  <TableCell rowSpan={2}><Typography># of Conversations (N)</Typography></TableCell>
                  <TableCell rowSpan={2} style={{width: 200}}><Typography>Top Buyer Roles displayed by this Persona</Typography></TableCell>
                  <TableCell colSpan={3} align="center"><Typography>Buying Intent Questions</Typography></TableCell>
                  <TableCell rowSpan={2} align="center">
                    <Tooltip title="Overall Buying Intent is High for Persona, if average across Buying Intent Questions is higher than 70%">
                      <Typography alignItems="center" style={{width: 110}}>Overall Buying Intent
                      <HelpOutlineIcon fontSize="small" sx={{verticalAlign: 'middle', fontSize: '18'}}/>
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {activeCampaign["buyingIntent"]["questions"].map((x, ind) => {
                    return (<TableCell key={ind}>{x["question"]}</TableCell>)
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {activeCampaign["cp"]["personas"].map((x, ind) => {
                  return (
                    <TableRow key={ind}>
                      <TableCell><Chip size="small" label={x["designation"] + ", " + x["vertical"]} style={{color: "#395af6"}}/></TableCell>
                      <TableCell>{activeCampaign["buyingIntent"]["total_conversations"][ind]}</TableCell>
                      <TableCell>
                      <Stack direction="column">
                        {activeCampaign["buyingIntent"]["persona_role"][ind]["roles"].map((y, ind_) => {
                          return (
                            <Stack direction="row" alignItems="center" key={ind_}><Typography sx={{color:"#395af6"}}>{y}</Typography>{": " + activeCampaign["buyingIntent"]["persona_role"][ind]["percentage"][ind_] + "%"}</Stack>
                          )
                        })}
                      </Stack>
                      </TableCell>
                      <TableCell>{activeCampaign["buyingIntent"]["questions"][0]["yes"][ind] + "%"}</TableCell>
                      <TableCell>{activeCampaign["buyingIntent"]["questions"][1]["yes"][ind] + "%"}</TableCell>
                      <TableCell>{activeCampaign["buyingIntent"]["questions"][2]["yes"][ind] + "%"}</TableCell>
                      <TableCell align="center">
                        {activeCampaign["buyingIntent"]["cumulative_buying_intent"][ind] >= 0.7?
                          <><Typography variant="h6">{Math.round(100*activeCampaign["buyingIntent"]["cumulative_buying_intent"][ind])/100 + "%"}</Typography><Typography color="#00cc33">High</Typography></>
                          :
                          <><Typography variant="h6">{Math.round(100*activeCampaign["buyingIntent"]["cumulative_buying_intent"][ind])/100 + "%"}</Typography><Typography color="red">Low</Typography></>
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        </Paper>
        </Grid>

        <Grid item xs={12}>
          {/*
          <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Recommendation(s)</Typography>
              <Typography>See our blog post to decide next steps, based on Problem Validation and Buying Intent results.</Typography>
              <Paper sx={{width: "250", backgroundColor: "#f5f5f5"}}>
                <a target="_blank" href="https://letsaspiro.com/blog/problem-validation-and-buying-intent-in-sales">
                  <center><img src="../../problem-validation-buying-intent.png" width="250" /></center>
                  <Divider />
                  <Paper sx={{width: "90%", height: "auto", padding: "12px", backgroundColor: "#f5f5f5"}} elevation={0}>
                    <center><Typography>The Role of Problem Validation and Buying Intent in Creating a Repeatable B2B Sales Motion</Typography></center>
                  </Paper>
                  <br />
                </a>
              </Paper>
            </Stack>
          </Paper>
          */}
          <Paper sx={{height: "100%", p: 3, boxSizing: 'border-box'}} elevation={1}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">Recommendation(s)</Typography>
              <Typography>See our blog post to decide next steps, based on Problem Validation and Buying Intent results.</Typography>
              <Paper sx={{width: "100%", backgroundColor: "#f5f5f5"}}>
                <a target="_blank" href="https://letsaspiro.com/blog/problem-validation-and-buying-intent-in-sales">
                  <Stack direction="row" alignItems="center">
                    <center><img src="../../problem-validation-buying-intent.png" width="100" /></center>
                    <Divider orientation='vertical'/>
                    <Paper sx={{width: "90%", height: "auto", padding: "12px", backgroundColor: "#f5f5f5"}} elevation={0}>
                      <Typography variation="">The Role of Problem Validation and Buying Intent in Creating a Repeatable B2B Sales Motion</Typography>
                    </Paper>
                  </Stack>
                </a>
              </Paper>
              <br />
              <center>
                <Button variant="contained" disabled={activeCampaign["status"] !== "Completed"} size="large" onClick={() => history.push("/icp/" + activeCampaign["cp"]["id"])}>
                  Edit Customer Profile and Buyer Personas
                </Button>
              </center>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    :<></>
    }
    </>
  )
}
