import React, {useState, useEffect} from 'react';
import {Typography} from '@mui/material';
// import { makeStyles } from '@mui/styles';
import useClasses from './utils/useClasses'
import { Router, Route, Switch, useHistory } from "react-router-dom";

import WithUser from './roleBasedAccess.js'
import ContentGrid from "./ContentGrid"
import DoesNotExist from "./utils/DoesNotExist"

const drawerWidth = 80;
//const navBarWidth = 64

const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 8,
    //padding: theme.spacing(1),
    //marginLeft: drawerWidth,
    marginTop: 18,
    maxHeight: "100%",
    width: "100%",
    overflowY: "auto"
  }
});

export default function MainContent(props) {
  const next_path = window.location.pathname
  const classes = useClasses(useStyles);
  //as useState initial value is used only on 1st render-
  //https://stackoverflow.com/a/59308313/9160820

  let history = useHistory()

  return (
      <main className={classes.content}>
        <Router history={history}>
          <Switch>
            <Route path="/team" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="team" />
              </WithUser>
            } />

            {/*
            <Route path="/companies/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" {...props} subMode="add_company"/>
              </WithUser>
            } />

            <Route path="/companies/:c_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" {...props} subMode="company_content"/>
              </WithUser>
            } />

            <Route path="/companies" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" subMode="all_companies" {...props} />
                {onboarded?
                  <></>:
                  <Onboarding setOnboarded={setOnboarded}/>
                }
              </WithUser>
            } />

            */ }

            <Route path="/icp/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="icp" {...props} subMode="add_cp"/>
              </WithUser>
            } />

            <Route path="/icp/:c_id/persona/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="icp" {...props} subMode="add_persona"/>
              </WithUser>
            } />

            <Route path="/icp/:c_id/persona/:p_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="icp" {...props} subMode="edit_persona"/>
              </WithUser>
            } />

            <Route path="/icp/:c_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="icp" {...props} subMode="cp_content"/>
              </WithUser>
            } />

            <Route path="/icp" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="icp" subMode="all_cp" {...props} />
              </WithUser>
            } />

            <Route path="/leads" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="leads" subMode="all_leads" {...props} />
              </WithUser>
            } />
            
            <Route path="/campaigns/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="campaigns" subMode="add_campaign" {...props}/>
              </WithUser>
            } />

            <Route path="/campaigns/edit_campaign/:c_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="campaigns" subMode="edit_campaign" {...props}/>
              </WithUser>
            } />

            <Route path="/campaigns/:c_id/dashboard" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="campaigns" {...props} subMode="campaign_analysis"/>
              </WithUser>
            } />

            <Route path="/campaigns/:c_id/edit" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="campaigns" {...props} subMode="campaign_content"/>
              </WithUser>
            } />

            <Route path="/campaigns" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="campaigns" subMode="all_campaigns" {...props} />
              </WithUser>
            } />

            <Route path="/integrations" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="integrations" {...props} />
              </WithUser>
            } />

            <Route path="/agents" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="agents" />
              </WithUser>
            } />

            <Route path="/home" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="home" />
              </WithUser>
            } />

            <Route path="/profile" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="profile" />
              </WithUser>
            } />

            <Route path="/settings" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="settings" />
              </WithUser>
            } />

            {/*
            <Route exact path="/tracks/:t_id/plays/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="add_play" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks/:t_id/plays/:m_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks/:t_id/plays/:m_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content_user" {...props}/>
              </WithUser>
            } />

            <Route exact path="/tracks/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="add_track" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks/:t_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="track_content" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks/:t_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="track_content_user" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks_user" {...props}/>
              </WithUser>
            } />

            <Route exact path="/attempt/:a_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content_user" {...props}/>
              </WithUser>
            } />
            */}

            <Route exact path="/" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="home" />
              </WithUser>
            } />

            {//Adding no match routes. Please don't add any routes below this
            }
            <Route path="/*" render={(props) =>
              <DoesNotExist />
            }/>

        </Switch>
        </Router>
      </main>
  );
}
