import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Skeleton, Typography} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { PERSONAS, STAGE_MAPPING } from "./../utils/constants"

import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ListIcon from '@mui/icons-material/List';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import AddPersona from "./AddPersona"
import AddCustomerProfile from "./AddCustomerProfile"
import CustomerProfileContent from "./CustomerProfileContent"

const customerProfileHeadingStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820
const customerProfileDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820
const personaDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
}
//
// function AddCustomerProfileCard(props){
//   return (
//     <Paper style={{height: "450px", width: "90%", borderRadius: "8px", borderStyle: "dashed", padding: "24px", backgroundColor: "#fbfafe"}}
//     elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.onClick}>
//       <div style={{marginTop: 210}}></div>
//       <center>
//       <Typography display="inline" variant="h6"><b>Create New ICP</b></Typography>
//       {/*<Typography style={{padding: "16px 0px 8px "}}>Add a new buyer company describing buying stories of one of your prominent customers.</Typography>
//       */}
//       <br />
//       <ControlPointIcon color="primary" fontSize="large"/>
//       </center>
//     </Paper>
//   )
// }

function PersonaCardViewOnly(props){
  const personaData = props.data
  return(
    <Paper style={{borderRadius: "8px", padding: "24px"}}>
    <Grid container direction="row">
    <Grid item>
      {personaData.designation}
      {personaData.vertical}
    </Grid>
    </Grid>
    </Paper>
  )
}

function CustomerProfileCard(props){
  const [delPopup, setDelPopup] = useState(false)
  const mode = props.mode

  const onDelete = (id) => {
    axios.delete(BACKEND_ADDRESS + '/cp/', {params: {id: id}})
    .then((response) => ToastComponent("info", "Customer Profile deleted successfully!"))
    .catch((error) => ToastComponent("error", "Couldn't delete Customer Profile"))
  }

  return (
    <>
      <Paper style={{height: "480px", width: "100%", borderRadius: "8px", padding: "24px", position: "relative", overflowY: "auto"}}
      elevation={4} >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            {mode === "empty"?
            <Skeleton variant="text" sx={{fontSize:"36", width: "200"}} />
            :
            <Typography display="inline" variant="h5" sx={customerProfileHeadingStyle}><b>{props.data["name"]}</b></Typography>
            }
            {/*<Chip size="small" label={props.data["stage"]} style={{background: 'linear-gradient(to right, primary.main, #19F4A6)', color: "white"}} />
            */}
          </Grid>
          <Grid item>
            {mode === "empty"?
            <Button startIcon={<ArrowCircleRightIcon />} variant="contained">
              See More
            </Button>
            :
            <Button onClick={() => props.selectCustomerProfile(props.data["_id"]["$oid"])} startIcon={<ArrowCircleRightIcon />}
            variant="contained">
              See More
            </Button>
            }
          </Grid>
        </Grid>
        
        {mode === "empty"?
        <Skeleton variant="text" sx={{fontSize: "16"}} />
        :
        <Typography style={{paddingTop: "8px"}} sx={customerProfileDescriptionStyle}>{props.data["description"]}</Typography>
        }
        <Divider sx={{margin: "12px 0"}}/>

        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <Typography><b>Industries</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["industries"].map((x, ind) =>
                  <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Verticals</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["verticals"].map((x, ind) =>
                  <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Geographies</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["geographies"].map((x, ind) =>
                  <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                )
              }
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography><b>Company Size</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {mode === "empty"?
                [1,2,3,4].map((x, ind) =>
                  <Skeleton key={ind}variant="text" sx={{width: "50", mb: 2}}/>
                )
                :props.data["company_size"].map((x, ind) =>
                  <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
                )
              }
            </Stack>
          </Grid>
          {/*
          <Grid item xs={6}>
            <Typography><b>Team Size</b></Typography>
            <Stack direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
              {props.data["team_size"].map((x, ind) =>
                <Chip key={ind} label={x} sx={{color: "primary.main"}} style={{marginBottom: 2}}/>
              )}
            </Stack>
          </Grid>
          */}
        </Grid>

        <Divider sx={{margin: "12px 0"}}/>
        <Typography sx={{mb: 0.5}}><b>Buyer Personas</b></Typography>

        {
        mode === "empty"?
          <Grid container direction="row" spacing={1}>
            {[1,2,3].map((x, ind) => 
              <Grid item key={ind}>
                <Paper sx={{width: "60px", borderRadius: "4px"}} elevation={0}>
                  <Avatar src={PERSONAS[x]} variant="square" sx={{width: "100%", height: "60px"}} />
                  <center><Skeleton variant="text" sx={{fontSize: "11px"}}/></center>
                </Paper>
              </Grid>
            )}
          </Grid>
        :
        props.data["personas"].length > 0?
          <Grid container direction="row" spacing={1}>
          {props.data["personas"].map((x, ind) => {
            return (
              <Grid item key={ind}>
                <Paper sx={{width: "60px", borderRadius: "4px"}} elevation={0}>
                  <Avatar src={PERSONAS[x["avatar"]]} variant="square" sx={{width: "100%", height: "60px"}} />
                  <center><Typography fontSize="11" sx={personaDescriptionStyle}>{x["designation"] + ", " + x["vertical"]}</Typography></center>
                </Paper>
              </Grid>
            )
          })}
          </Grid>
        :<Typography variant="subtitle2"><i>No Customer Personas created yet!</i></Typography>
        }
      </Paper>

      <Dialog open={delPopup} onClose={() => setDelPopup(false)}>
        <DialogTitle>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            All progress/history of this customer profile will be deleted!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelPopup(false)} autoFocus>Cancel</Button>
          <Button onClick={onDelete} style={{color: "red"}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const emptyCustomerProfile = {
  name: "",
  description: "",
  problems: ["", "", ""],
  industries: [],
  verticals: [],
  company_size: [],
  team_size: [],
  geographies: [],
  stage: "Problem Validation", //Problem Validation/Early Sales
  personas: [],
  buying_signals_tech: [],
  buying_signals_non_tech: [],
}

export default function CustomerProfile(props) {
  const activePage = props.subMode
  const [customerProfiles, setCustomerProfiles] = useState(null)
  const account_id = useSelector((state) => state.account.value)

  useEffect(() => {
    axios.get(BACKEND_ADDRESS + '/cp/all_cps/', {params: {account_id: account_id}})
    .then((response) => {
      var result = response.data["customer_profiles"].reverse()
      result = result.map((x, ind) => {return {ind: ind, ...x}})
      setCustomerProfiles(result)
    })
  }, [])

  var activeCustomerProfile = null
  var activePersona = null

  if(customerProfiles !== null){
    if(activePage === "cp_content" || activePage === "add_persona" || activePage === "edit_persona"){
      for(var i=0;i<customerProfiles.length;i+=1){
        if(customerProfiles[i]["_id"]["$oid"] === props.match.params.c_id){
          activeCustomerProfile = customerProfiles[i]
          break
        }
      }
  
      if(activePage === "edit_persona" && activeCustomerProfile !== null){
        for(i=0;i<activeCustomerProfile["personas"].length;i+=1){
          if(activeCustomerProfile["personas"][i]["_id"]["$oid"] === props.match.params.p_id){
            activePersona = activeCustomerProfile["personas"][i]
            break
          }
        }
      }
    }
  }

  useEffect(() => {
    if(activePage === "all_cp"){
      props.callback("heading", "Customer Profiles")
      props.callback("description", "List of all Customer Profiles that you are selling to")
    }else if(activePage === "cp_content" && activeCustomerProfile !== null){
      props.callback("heading", activeCustomerProfile.name)
      props.callback("description", activeCustomerProfile.description)
    }else if(activePage === "add_cp"){
      props.callback("heading", "Create Customer Profile")
      props.callback("description", "Describe new Customer Profile")
    }else if(activePage === "add_persona"){
      props.callback("heading", "Add Persona")
      props.callback("description", "Add new Persona in a Customer Profile")
    }else if(activePage === "edit_persona" && activePersona !== null){
      props.callback("heading", activePersona["designation"] + " at " + activePersona["vertical"])
      props.callback("description", "Edit persona to reflect your updated understanding")
    }

    window.scrollTo(0,0) //scrolling to top of new screen
  }, [activePage, activeCustomerProfile, activePersona])

  let history = useHistory();

  const selectCustomerProfile = (ind) => {
    history.push('/icp/' + String(ind));
  }

  const addCustomerProfile = () => {
    history.push('/icp/add_new');
  }

  return (
    activePage === "all_cp"?
      <>
      <Typography>Have a new Customer in mind?</Typography>&nbsp;
      <Button variant="contained" autoFocus startIcon={<ControlPointIcon />} style={{textTransform: 'none'}} onClick={addCustomerProfile}>
        Create New Customer Profile
      </Button>
      <div style={{marginTop: "30"}}/>
      <Grid container direction="row" spacing={2}>
        {customerProfiles === null?
          [1,2,3].map((_, ind) => {
            return (
              <Grid item key={ind} xs={12} md={6} lg={4}>
                <CustomerProfileCard mode="empty" />
              </Grid>
            )
          })
        :customerProfiles.map((val, ind) => {
          return (
            <Grid item key={ind} xs={12} md={6} lg={4}>
              <CustomerProfileCard data={val} ind={1} selectCustomerProfile={selectCustomerProfile} history={history}/>
            </Grid>
          )
        })}
      </Grid>
      </>
      :
    activePage === "add_cp"?
      <AddCustomerProfile mode="add" data={emptyCustomerProfile}/>
      :
    customerProfiles !== null && activePage === "cp_content"?
      <CustomerProfileContent customerProfile={activeCustomerProfile} {...props}/>
      :
    customerProfiles !== null && activePage === "add_persona"?
      <AddPersona customerProfile={activeCustomerProfile} mode="add" />
      :
    customerProfiles !== null && activePage === "edit_persona"?
      <AddPersona customerProfile={activeCustomerProfile} mode="edit" p_id={props.match.params.p_id}/>
    :<></>
  )
}
