import {Grid, Stack, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';

export default function TipBox(props){
  return (
    <Paper style={{borderRadius: "8px", padding: "24px", backgroundColor: "#f1f1ef"}} elevation={0}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h5" sx={{width: "70"}}>💡</Typography>
        {props.tip}
      </Stack>
    </Paper>
  )
}
