import React, { useState, useEffect, useRef } from 'react';
import {Skeleton, FormHelperText, Typography, Box, IconButton, Button, Stack, Grid, Chip, Paper, TextField, Radio, Checkbox, RadioGroup, Divider} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import TipBox from "./../utils/TipBox"

import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import { HighlightText, useUndoRedo } from '../utils/helper';
import { useSelector } from 'react-redux'
import ToastComponent from '../utils/ToastComponent';

const allChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc.", "Cold Call", "Face-to-face/At Events"]
//only first two channels allowed in followup
const allFollowUpChannels = ["Email/LinkedIn InMail", "WhatsApp/LinkedIn/Twitter DM etc."]

export default function FollowUp(props){
  var listNo = props.listNo
  var leadNo = props.leadNo
  var campaign = props.campaign
  var leadList = campaign["leads"][listNo]
  var channel = campaign["lead_status"][listNo][leadNo][1]["channel"]
  var oldSubject = campaign["lead_status"][listNo][leadNo][1]["subject"]
  var oldMessage = campaign["lead_status"][listNo][leadNo][1]["message"]
  var oldScript = campaign["lead_status"][listNo][leadNo][1]["script"]
  const oldLead = (campaign["lead_status"][listNo][leadNo].length > 2 && (campaign["lead_status"][listNo][leadNo][2]["status"] === "completed"))
  
  const [followUpChannel, setFollowUpChannel] = useState(oldLead?campaign["lead_status"][listNo][leadNo][2]["followUpChannel"]:"")
  const [followUpChannelError, setFollowUpChannelError] = useState(false)

  const { state: content, undo, redo, updateState: updateContent } = useUndoRedo({
    subject: oldLead?campaign["lead_status"][listNo][leadNo][2]["subject"]:"",
    message: oldLead?campaign["lead_status"][listNo][leadNo][2]["message"]:"",
    script: oldLead?campaign["lead_status"][listNo][leadNo][2]["script"]:"",
  });
  const [instruction, setInstruction] = useState("")

  const [loadingMsg, setLoadingMsg] = useState("")
  const [gotAllInputs, setGotAllInputs] = useState(oldLead)

  const sellerName = useSelector((state) => state.name.value);
  const account = useSelector(state => state.account.value)
  const hasProduct = campaign["has_product"]
  const [sellerCompany, setSellerCompany] = useState("")

  useEffect(() => {
    if(oldLead) return
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then(response => setSellerCompany(response.data["company_name"]))
  }, [])

  const copyToClipboard = () => {
    var flag = true

    //if(section === "message" || section === "script"){
      if(followUpChannel === ""){
        setFollowUpChannelError(true)
        flag = false
      }else setFollowUpChannelError(false)
    //}

    if(flag){
      var copyText = ""
      if(content["subject"] !== "") copyText += "Subject: " + content["subject"] + "\n\n"
      if(content["message"] !== "") copyText += "Message: " + content["message"] + "\n\n"
      if(content["script"] !== "") copyText += "Script: " + content["script"] + "\n\n"

      navigator.clipboard.writeText(copyText)
      ToastComponent("info", "Copied to Clipboard Successfully!")
      
      var obj = {
        type: "Follow Up", 
        status: "completed",
        subject: content["subject"],
        message: content["message"],
        script: content["script"],
        channel: channel,
        followUpChannel: followUpChannel
      }
  
      var newActiveCampaign = JSON.parse(JSON.stringify(campaign))
      const statusLength = newActiveCampaign["lead_status"][listNo][leadNo].length
      newActiveCampaign["lead_status"][listNo][leadNo][statusLength - 1] = obj
  
      // update everywhere 
      axios.put(BACKEND_ADDRESS + '/campaign/', newActiveCampaign)
      props.callback(newActiveCampaign) // in the very end
    } 
    else ToastComponent("error", "Failed to copy!")
  }

  useEffect(() => {
    if(oldLead || followUpChannel === "") return

    setGotAllInputs(true)
    var ind = 0
    for(var i =0;i<allFollowUpChannels.length;i+=1){
      if(allFollowUpChannels[i] === followUpChannel){
        ind = i
        break
      }
    }

    var data = {
      subject: oldSubject,
      message: oldMessage,
      script: oldScript,
      channel: channel,
      followUpChannel: followUpChannel,
      copyData: campaign["copy_data"],

      problem: campaign["lead_status"][listNo][leadNo][1]["problem"],
      cta: campaign["lead_status"][listNo][leadNo][1]["cta"],
      meetingLink: campaign["lead_status"][listNo][leadNo][1]["meetingLink"],
      areas: campaign["lead_status"][listNo][leadNo][1]["areas"],
      referenceName: campaign["lead_status"][listNo][leadNo][1]["referenceName"],
      industry: campaign["leads"][listNo]["metadata"]["industry"][leadNo],
      vertical: campaign["leads"][listNo]["metadata"]["vertical"][leadNo],
      name: campaign["leads"][listNo]["data"]["Name"][leadNo],

      sellerName: sellerName,
      hasProduct: hasProduct,
      sellerCompany: sellerCompany
    }

    var interval = setInterval(() => {
      const text = "Generating Follow Up"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)

    axios.post(BACKEND_ADDRESS + '/campaign/generate_follow_up/', data)
    .then(response => {
        var result = response.data
        updateContent({
          subject: result["subject"],
          message: result["message"],
          script: result["script"],
        })

        clearInterval(interval)
        setLoadingMsg("")
    }).catch(e => {
      clearInterval(interval)
      setLoadingMsg("")
    })
  }, [followUpChannel])

  const onPersonalise = (method) => {
    var interval = setInterval(() => {
      const text = "Personalising"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)

    axios.post(BACKEND_ADDRESS + '/campaign/personalise_message/', {type: method, subject: content["subject"], message: content["message"], script: content["script"]})
    .then(response => {
      updateContent({
        subject: content["subject"] !== ""?response.data["subject"]:content["subject"],
        message: content["message"] !== ""?response.data["message"]:content["message"],
        script: content["script"] !== ""?response.data["script"]:content["script"],
      })

      //temporary
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("info", "Coming Soon!")
    }).catch(e => {
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("error", "Can't personalise. Try again!")
    })
  }

  const onRewrite = (inst) => {
    inst = inst?inst:instruction
    
    var interval = setInterval(() => {
      const text = "Rewriting"
      setLoadingMsg(loadingMsg => {
        const dotLength = ((loadingMsg.length === 0)?3:(loadingMsg.length - text.length))
        return (dotLength === 3)?(text + "."):(loadingMsg + ".")
      })
    }, 1000)
    
    axios.post(BACKEND_ADDRESS + '/campaign/rewrite_message/', {instruction: inst, subject: content["subject"], message: content["message"], script: content["script"]})
    .then(response => {
      updateContent({
        subject: content["subject"] !== ""?response.data["subject"]:content["subject"],
        message: content["message"] !== ""?response.data["message"]:content["message"],
        script: content["script"] !== ""?response.data["script"]:content["script"],
      })
      //temporary
      setLoadingMsg("")
      clearInterval(interval)
      setInstruction("") //reset
    }).catch(e => {
      setLoadingMsg("")
      clearInterval(interval)
      ToastComponent("error", "Can't rewrite. Try again!")
    })
  }

  return (
    
        <Paper elevation={0} sx={{width: "100%", height: "95vh", overflowY: "auto", border: "1px solid rgba(0,0,0,0.12)", borderRadiusTopRight: 2, borderRadiusBottomRight: 2}}>
          <Grid container direction="row" spacing={2} sx={{height: "100%"}}>
            <Grid item xs={12} md={4}>
              <Paper square sx={{padding: "24 16", height: "100%", minHeight: "500px", borderRight:"1px solid rgba(0, 0, 0, 0.12)", backgroundColor: "#f5f5f5"}} elevation={0}>
                <Stack direction="column" spacing={1} sx={{mb:4}}>
                  <FormLabel sx={{color: "#395af6"}}>Copy/Script Settings</FormLabel>
                  <div style={{marginTop: "12"}}></div>
                  <FormControl sx={{width: "95%"}} required>
                    <InputLabel sx={{ml: -2}}>First Contact Channel</InputLabel>
                    <Select size="small" required value={channel} disabled={gotAllInputs}
                    variant="standard">
                      <MenuItem value={channel}>{channel}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{width: "95%"}} required error={followUpChannelError}>
                    <InputLabel sx={{ml: -2}}>Follow Up Channel</InputLabel>
                    <Select size="small" autoFocus required value={followUpChannel} disabled={gotAllInputs}
                    variant="standard" onChange={(e) => {setFollowUpChannel(e.target.value)}}>
                      {allFollowUpChannels.map((x, ind) => 
                        <MenuItem key={ind} value={x}>{x}</MenuItem>
                      )}
                    </Select>
                    {followUpChannelError?<FormHelperText sx={{ml:0}}>Select a Channel</FormHelperText>:<></>}
                  </FormControl>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={{padding: "24 16", height: "100%"}} elevation={0}>
                {loadingMsg !== ""?
                  <Stack direction="column" sx={{width: "100%", height:"100%"}} alignItems="center" justifyContent="center">
                    <Typography variant="h6">
                      {loadingMsg}
                    </Typography>
                  </Stack>
                  :
                  followUpChannel !== ""?
                  <Stack direction="column" sx={{width: "100%", flexWrap: 'wrap'}} spacing={1} >
                    <Stack direction="column" spacing={4} sx={{mt:0}}>
                      {content["subject"] !== ""?
                        <Typography key={content["subject"]} label="Subject" style={{userSelect: 'none'}}><b>Subject</b><br /> {content["subject"]}</Typography>
                        :<></>
                      }
                      {content["message"] !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={content["message"]}><b>Message</b><HighlightText text={content["message"]} /></Box>
                        :<></>
                      }
                      {content["script"] !== ""?
                        <Box sx={{whiteSpace: "pre-wrap"}} key={content["script"]}><b>Script</b><HighlightText text={content["script"]} /></Box>
                        :<></>
                      }
                    </Stack>
                    
                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end">
                      <Stack direction="column" alignItems="center">
                        <IconButton size="small" onClick={undo} disabled={!gotAllInputs}>
                          <UndoIcon fontSize="small"/>
                        </IconButton>
                        
                      </Stack>
                      <Stack direction="column" alignItems="center">
                        <IconButton size="small" onClick={redo} disabled={!gotAllInputs}>
                          <RedoIcon fontSize="small"/>
                        </IconButton>
                      </Stack>
                      <Divider orientation="vertical" flexItem style={{margin: "0 6", backgroundColor: "rgba(0,0,0,0.25)"}}/>
                      <Button size="small" variant="contained" startIcon={<ContentCopyIcon />} disabled={!gotAllInputs} onClick={copyToClipboard}>Send</Button>
                    </Stack>
                    
                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>🪄 Rewrite</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it formal")}>Formal</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it casual")}>Casual</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it humorous")}>Humorous</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onRewrite("Make it serious")}>Serious</Button>
                      <Stack direction="row" alignItems="center">
                        <TextField sx={{"& .MuiInputBase-input": {height: "30px", padding: "0 14px"}}} placeholder="Custom Instruction" value={instruction} onChange={e => setInstruction(e.target.value)} />
                        <Button size="small" variant="outlined" onClick={() => onRewrite()}>Rewrite</Button>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-end" sx={{flexWrap: 'wrap'}}>
                      <Typography>Personalise using Buyer's</Typography>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(1)}>Social Media Post</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(2)}>Work & Expertise</Button>
                      <Button size="small" variant="outlined" sx={{borderRadius: 16}} onClick={() => onPersonalise(3)}>Company/Industry News</Button>
                    </Stack>
                  </Stack>
                  :<></>
                }
              </Paper>
            </Grid>
          </Grid>
        </Paper>   
  )
}